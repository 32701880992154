
import { defineComponent } from 'vue';
// import HelloWorld from './components/HelloWorld.vue';
import IndexView from './views/home/New.vue';

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld,
    IndexView,
  }
});
