
import { defineComponent, ref } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import type { CarouselProps } from 'ant-design-vue';

export default defineComponent({
  name: 'IndexView',
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  setup() {
    const imgs = [
      {
        key: 2,
        src: require('../../assets/images/img_02.png'),
      },
      {
        key: 3,
        src: require('../../assets/images/img_03.png'),
      },
      {
        key: 4,
        src: require('../../assets/images/img_04.png'),
      },
      {
        key: 5,
        src: require('../../assets/images/img_05.png'),
      },
    ];

    return {
      imgs,
      dotPosition: ref<CarouselProps['dotPosition']>('bottom'),
    };
  },
});
